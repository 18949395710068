/**
 * Every route becomes a chunk, loaded only when used.
 * Reduces size of initial App load.
 */

const CreateTicket = () => import(/* webpackChunkName: "create-ticket" */ "@/views/CreateTicket.vue");

const AllTicket = () => import(/* webpackChunkName: "all-ticket" */ "@/views/AllTicket.vue");

const TicketChatLogList = () => import(/* webpackChunkName: "ticket-chat-log-list" */ "@/views/TicketChatLogList.vue");

import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
 {
    name:"AllTicket",
    path:"/",
    component:AllTicket,
  },


  {
    name: "CreateTicket",
    path: "/create-ticket",
    component: CreateTicket,
  },

 

  {
    name:"TicketChatLogList",
    path:"/ticket-chat-log-list",
    component:TicketChatLogList,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
